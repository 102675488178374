import React, { useState } from 'react'
import { Button, Col, Form, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { get } from 'src/apis/apiService'
import { AUTH_APIS } from 'src/apis/authApi'
import { ForgetPasswordModal, StyledWrapper } from './styled'

export const ForgetPassword = () => {
  const [show, setShow] = useState(false)
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)

  const handleClose = () => setShow(false)

  const handleClick = (e) => {
    e.preventDefault()
    setShow(true)
  }

  const handleInput = (e) => setEmail(e.target.value.toLowerCase())

  const handleSubmit = (e) => {
    setLoading(true)
    e.preventDefault()
    get(
      AUTH_APIS.forgetPassword,
      (data) => {
        setLoading(false)
        if (data.exist) {
          toast('We have sent you an email, please check your mailbox for the password.')
          setShow(false)
          setEmail('')
        } else {
          toast('This email does not seem to be registered to our system.')
        }
      },
      (error) => {
        setLoading(false)
        toast(error.response?.data?.message)
      },
      { email }
    )
  }
  
  const showHelpDesk = () => {
    const cpt = window.chaport
    cpt.open()
  }

  return (
    <>
      <StyledWrapper>
        <div id="btn" className='my-2' onClick={handleClick}>
          <small style={{ color: '#fff', fontSize: '12px', fontWeight: 'bold' }}>Forgot Password? <span style={{ color: '#F4B64E'}}>Click Here</span>&nbsp;</small>
        </div>
        <div id="btn" className='my-2'>
        <small style={{ color: '#fff', fontSize: '12px', fontWeight: 'bold' }}>Need Help? <span onClick={() => showHelpDesk()} style={{ color: '#F4B64E'}}>Click Here</span>&nbsp;</small>
        </div>
      </StyledWrapper>
      <ForgetPasswordModal show={show} onHide={handleClose} size="md" aria-labelledby="schedule-modal" centered>
        <Modal.Header closeButton>
          <Modal.Title>Forgot Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Row>
            <Form.Group as={Col} controlId="formGridEmail">
              <Form.Label>Enter Email to request for a new Password</Form.Label>
              <Form.Control type="email" placeholder="Enter email here..." onChange={handleInput} value={email} />
            </Form.Group>
          </Form.Row>
          <Button
            id="verify-btn"
            disabled={email.length < 4 || loading}
            variant="warning"
            onClick={handleSubmit}
          >
            {loading ? 'Reseting...' : 'Reset Password'}
          </Button>
        </Modal.Body>
      </ForgetPasswordModal>
    </>
  )
}
