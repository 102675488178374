import React, { useState, useEffect, Suspense } from 'react'
import styled from 'styled-components'
import StreamPlayer from 'src/components/StreamPlayer'
import { Col, Container, Row } from 'react-bootstrap'
import config from 'src/config'
import Axios from 'axios'
import { Loader } from 'src/components/shared/Loader'

const Page = styled.div`
  min-height: 100vh;
  padding: 1rem 0;
`

export default function TestLivePage() {
  const [streams, setStreams] = useState([])
  const [loading, setLoading] = useState(true)
  
  useEffect(() => {
    const fetchStreams = () => {
      Axios
      .get(`${config.apiUrl}/api/streams`, {})
      .then(({ status, data }) => {
        console.log("data:",data.streams)
        setStreams(data.streams)
        setLoading(false)
      })
      .catch((err) => {
        console.error('Error: ', err)
      })
    }
    fetchStreams()
  }, [])

  return (
    <Loader fullPage loading={loading}>
      <Suspense fallback={<div>Loading...</div>}>
        <Page>
          <Container>
            <Row noGutters>
              {streams.map(stream => (
                <Col md={12} key={stream.name} className='card p-5 mb-5'>
                  <h6>{stream.name}</h6>
                  <p>{stream.url}</p>
                  {stream.url.length > 0 ?
                    <StreamPlayer streamUrl={stream.url} />
                    :
                    <p>No Link Found.</p>
                  }
                </Col>
              ))}
            </Row>
          </Container>
        </Page>
      </Suspense>
    </Loader>
  )
}
