import Axios from 'axios'
import React, { KeyboardEventHandler, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import config from '../../config'
import routes from '../../routes'
import RegistrationTemplate from './RegistrationTemplate'
import styled from 'styled-components'
import { salutations } from 'src/constants/salutation'
import { specialties } from 'src/constants/specialities'
import { Registration } from 'src/types/registration'
import { useForm, SubmitHandler } from "react-hook-form"
import { Loader } from 'src/components/shared/Loader'
import { SIZES } from 'src/styles/theme'

const FormWrapper = styled.div`
 display: flex;
 justify-content: center;
 padding-left: 3.3rem;

 ${SIZES.mobile} {
  padding: 0
 }
`
const SubmitButton = styled(Button)`
    border-radius: 4rem;
    color: #000;
    text-transform: capitalize;
    font-weight: bold;
    padding: 9px 43px;
    background: linear-gradient(90deg, #FCE7B6 0%, #F4B64E 100%);
    cursor: pointer;
    width: fit-content;
    border: 0px;
    font-size: 1.2rem;

    &:hover {
      background: #F4B64E
    }

    ${SIZES.mobile} {
      width: 100%;
      font-size: 8px 41px;
    }
    `;

export default function VirtualRegistration() {
  const history = useHistory()

  const {
    register,
    setError,
    clearErrors,
    handleSubmit: formHandler,
    formState: { errors },
  } = useForm<Registration>()

  // const emailWatch = watch('email')
  const [hasError, setHasError] = useState<boolean>(false);
  const [data, setData] = useState<Registration>({
    salutation: null, 
    fullName: null,
    email: null,
    mobileNo: null,
    icNumber: null,
    mpsNumber: null, 
    specialty: null,
    placeOfPractice: null,
    otherSpecialty: null
  })

  const [loading, setLoading] = useState(false)

  const handleInput = (e) => {
    const { name, value } = e.target
    
    if (name === 'email') {
      // Email validation regex pattern
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (!emailPattern.test(value)) {
        setError('email', {
          type: 'pattern',
          message: 'Invalid email format',
        });
      }else{
        clearErrors('email')
      }
    }

    setData({
      ...data,
      [name]: value,
      [name]: name === 'mobileNumber' && mobileNumberHandler(value),
      [name]: name === 'email' ? value.toLowerCase() : value
    })

  }

  const mobileNumberHandler = (event) => {
    const { key, target } = event;
    const value = target.value;

    // Allow control keys to be used: backspace, delete, arrow keys
    const controlKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'];
    if (controlKeys.includes(key)) {
        return; // Let these keys function normally
    }

    // Prevent any key press that is not a number or a dash
    if (!key.match(/[0-9-]/)) {
        event.preventDefault();
        return;
    }

    // Prevent more than one dash or dash not in the fourth position
    if (key === '-' && (value.includes('-') || target.selectionStart !== 3)) {
        event.preventDefault();
        return;
    }

    // Automatically add a dash after the third number if not present
    if (value.length === 3 && key.match(/[0-9]/) && !value.includes('-')) {
        target.value = value + '-' + key;
        event.preventDefault();
        return;
    }

    // Ensure the max length is 11 excluding the dash
    if ((value.replace('-', '').length >= 11) && !controlKeys.includes(key)) {
        event.preventDefault();
        return;
    }

    return value;
};

  
  const icNumberHandler = (event: any) => {
    const { key, target } = event;
    const value = target.value;
    const selectionStart = target.selectionStart;

    // Allow control keys to be used: backspace, delete, arrow keys
    const controlKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'];
    if (controlKeys.includes(key)) {
        return; // Let these keys function normally
    }

    // Prevent any key press that is not a number or a dash
    if (!key.match(/[0-9-]/)) {
        event.preventDefault();
        return;
    }

    // Prevent more than two dashes or dashes not in the correct positions
    const dashPositions = [6, 9]; // Positions after which a dash should be placed
    if (key === '-') {
        if (value.split('-').length - 1 >= 2 || !dashPositions.includes(selectionStart)) {
            event.preventDefault();
            return;
        }
    }

    // Automatically add dashes after the sixth and eighth numbers if not present
    if ((value.length === 6 || value.length === 9) && key.match(/[0-9]/) && value.split('-').length - 1 < 2) {
        target.value = value + '-' + key;
        event.preventDefault();
        return;
    }

    // Ensure the max length is 14 (including the dashes)
    if (value.length >= 14 && !controlKeys.includes(key)) {
        event.preventDefault();
        return;
    }
  };

  const onFormErrorHandler = (data) => {
    setHasError(true) 
  }

  const onSubmitFormHandler = (data: Registration) => {
    setLoading(true)
    const options = {
      full_name: data.fullName,
      salutation: data.salutation,
      email: data.email,
      mobile_phone: data.mobileNo,
      ic_number: data.icNumber,
      mps_number: data?.mpsNumber,
      place_of_practice: data.placeOfPractice,
      specialty: data.specialty,
      other_specialty: data.otherSpecialty ? data.otherSpecialty : null
    }

    Axios.post(`${config.apiUrl}/api/users`, options)
      .then(() => {
        history.push(routes.virtualRegComplete)
      })
      .catch((err) => {
        toast(err?.response?.data?.message)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const { salutation, fullName, email, mobileNo, icNumber, mpsNumber, placeOfPractice, specialty } = data

  return (
    <RegistrationTemplate>
      <FormWrapper>
      <Form 
        noValidate 
        validated={hasError} 
        style={{ 
          width: '100%', 
          maxWidth: '600px',
          position: 'relative'
         }}
         className="registration"
      >
        {loading && <div style={{ 
          borderRadius: '0.5rem', 
          background: '#f0f0f0', 
          width: '100%',  
          height: '100%', 
          position: 'absolute', 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center' }}> <Loader loading/> </div>}
        <div style={{ 
          padding: '1.5rem',
          borderRadius: '0.5rem'
        }}>
          <Form.Group style={{ marginBottom: '1.5rem' }}>
          <select className="form-control" style={{ height: 'calc(2em + .75rem + 2px)' }} value={salutation} {...register('salutation', { required: true })}  name="salutation" onChange={handleInput} required>
            <option value="" disabled selected>
              Salutation*
            </option>
            {salutations.map((salutation) => <option key={salutation} value={salutation}>{salutation}</option>)}
          </select>
          {errors.salutation && (
            <span style={{ color: 'red' }}>Salutation is required</span>
          )}
        </Form.Group>

        <Form.Group style={{ marginBottom: '1.5rem' }}>
          <input className="form-control" style={{ height: 'calc(2em + .75rem + 2px)' }} required type="text" {...register('fullName', { required: true })} name="fullName" value={fullName} onChange={handleInput} placeholder="Full name as per NRIC*" />
          {errors.fullName && (
            <span style={{ color: 'red' }}>Full name is required</span>
          )}
        </Form.Group>

        <Form.Group style={{ marginBottom: '1.5rem' }}>
          <input 
            className="form-control" 
            style={{ height: 'calc(2em + .75rem + 2px)' }} 
            required 
            type="text" 
            {...register('icNumber', { required: true, pattern: {
              value: /^\d{6}-\d{2}-\d{4}$/,
              message: "Invalid IC number format. Expected format: 123456-12-1234"
            }  })}  
            name="icNumber" 
            value={icNumber} 
            onKeyDown={icNumberHandler} 
            onChange={handleInput} 
            placeholder="IC Number* (for CPD point application)" />
          {errors.icNumber && (
            <span style={{ color: 'red' }}>IC number is required</span>
          )}
        </Form.Group>

        <Form.Group style={{ marginBottom: '1.5rem' }}>
          <input className="form-control" style={{ height: 'calc(2em + .75rem + 2px)' }} type="text" name="mpsNumber" {...register('mpsNumber', { required: false })} value={mpsNumber} onChange={handleInput} placeholder="MPS Number" />
        </Form.Group>

        <Form.Group style={{ marginBottom: '1.5rem' }}>
          <input className="form-control" style={{ height: 'calc(2em + .75rem + 2px)' }} required type="text" name="mobileNo" {...register('mobileNo', { required: true })}  onKeyDown={mobileNumberHandler} value={mobileNo} onChange={handleInput} placeholder="Mobile No*" />
          {errors.mobileNo && (
            <span style={{ color: 'red' }}>Mobile number is required</span>
          )}
        </Form.Group>

        <Form.Group style={{ marginBottom: '1.5rem' }}>
          <input 
            className="form-control" 
            style={{ height: 'calc(2em + .75rem + 2px)' }} 
            required 
            type="email" 
            name="email" 
            {...register('email', { 
              required: true, pattern: {
              value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
              message: "Invalid email address"
            } })}  
            value={email} 
            onChange={handleInput} 
            placeholder="Email*" />
          {errors.email && (
            <span style={{ color: 'red' }}>{errors.email?.type === 'required' ? 'Email is required' : 'Invalid Email format'}</span>
          )}
        </Form.Group>

        <Form.Group style={{ marginBottom: '1.5rem' }}>
          <input className="form-control" style={{ height: 'calc(2em + .75rem + 2px)' }} required type="input" {...register('placeOfPractice', { required: true })} name="placeOfPractice" value={placeOfPractice} onChange={handleInput} placeholder="Place of Practice*" />
          {errors.mobileNo && (
            <span style={{ color: 'red' }}>Place of practice is required</span>
          )}
        </Form.Group>

        <Form.Group style={{ marginBottom: '1.5rem' }}>
          <select className="form-control" style={{ height: 'calc(2em + .75rem + 2px)' }} value={specialty} {...register('specialty', { required: true })} name="specialty" onChange={handleInput} required>
            <option value="" disabled selected>
              Specialty*
            </option>
            {specialties.map((specialty) => <option key={specialty} value={specialty}>{specialty}</option>)}
          </select>
          {errors.specialty && (
            <span style={{ color: 'red' }}>Specialty is required</span>
          )}
        </Form.Group>

        {data.specialty && data.specialty === 'Others' && <Form.Group style={{ marginBottom: '1.5rem' }}>
          <input className="form-control" style={{ height: 'calc(2em + .75rem + 2px)' }} required type="input" name="otherSpecialty" {...register('otherSpecialty', { required: data.specialty === 'Others' })} value={data.otherSpecialty} onChange={handleInput} placeholder="Please specify" />
          {errors.otherSpecialty && (
            <span style={{ color: 'red' }}>Please specify your specialty</span>
          )}
        </Form.Group>}

        <div className="d-flex justify-content-end mt-4">
          <SubmitButton onClick={formHandler(onSubmitFormHandler, onFormErrorHandler)}>{loading ? 'SUBMITTING...' : 'SUBMIT'}</SubmitButton>
        </div>
        </div>
        
      </Form>
      </FormWrapper>  
    </RegistrationTemplate>
  )
}
