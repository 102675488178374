export const salutations = [
    'Prof',
    'Prof Dr',
    "Prof Dato' Dr",
    'Assoc Prof',
    'Assoc Prof Dr',
    'Asst Prof',
    'Asst Prof Dr',
    'Dr',
    'Datuk',
    'Emeritus Prof Datuk Dr',
    'Datuk Dr',
    "Dato'",
    "Dato' Dr",
    "Dato' Sri Dr",
    'Datin',
    'Mr',
    'Mdm',
    'Ms'
];
