import React from 'react'
import { Button, Col, Container, Form } from 'react-bootstrap'
import Media from 'react-media'
import { fixedAssets } from 'src/containers/PreCache'
import { FONTS, SIZES } from 'src/styles/theme'
import styled from 'styled-components'
import CountdownTimer from '../CountdownTimer'
import routes from 'src/routes'

const Page = styled.div`
    min-height: 100vh;
    background-image: url(${fixedAssets.welcomeBgMobile});
    background-color: #212529;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;

    ${SIZES.desktop} {
      background-image: url(${fixedAssets.registrationBg});
    }
`

const CustomButton = styled(Button)`
    border-radius: 4rem;
    color: #000;
    text-transform: capitalize;
    font-weight: bold;
    padding: 9px 43px;
    background: linear-gradient(90deg, #FCE7B6 0%, #F4B64E 100%);
    cursor: pointer;
    width: fit-content;
    border: 0px;
    font-size: 1.2rem;
    margin-top: 1rem;

    &:hover {
      background: #F4B64E
    }

    ${SIZES.mobile} {
      font-size: 8px 41px;
    }
    `;
    const showHelpDesk = () => {
      const cpt = window.chaport
      cpt.on('ready', function () {
        cpt.open()
      })
    }
export default function RegistrationTemplate({ children, showCountdown=false }: { children: React.ReactNode; showCountdown?: boolean; }) {
  return (
    <Media query={`(max-width: ${SIZES.mobileBreakpoint})`}>
      {(matches) =>
        matches ? (
          <Page>
            <div className='mobile' style={{ position: 'relative', zIndex: 10 }}>
                <img 
                  src={fixedAssets.welcomeTitleMobile} 
                  alt='' 
                  style={{
                    width: '100%',
                    marginTop: '-4rem',
                    marginLeft: '-2rem',
                    maxWidth: '450px'
                  }}  
                />
                <a href={routes.login} style={{display: 'block', textAlign: 'center'}}>
                  <CustomButton>
                    Enter Auditorium
                  </CustomButton>
                  <div style={{ color: '#fff', display: 'block'}}>Need Help? <span onClick={() => showHelpDesk()} style={{ color: '#F4B64E', cursor: 'pointer'}}>Click Here</span></div>
                </a>
                
            </div>
            
            <div style={{ padding: '1rem'}}>
              { children }
            </div>
          </Page>
        ) : (
          <Page>
           <div className="d-flex">
              <div className='d-flex flex-column align-items-center'>
                <img 
                  src={fixedAssets.welcomeTitleMobile} 
                  alt='' 
                  style={{
                    width: '100%',
                    maxWidth: '500px',
                    marginTop: '-6rem',
                  }}/>
                  <a href={routes.login} style={{display: 'block', marginLeft: '50%'}}>
                    <CustomButton>
                      Enter Auditorium
                    </CustomButton>
                  </a>
                  <div style={{ color: '#fff', display: 'block', marginLeft: '50%'}}>Need Help? <span onClick={() => showHelpDesk()} style={{ color: '#F4B64E', cursor: 'pointer'}}>Click Here</span></div>
              </div>
              
              <div className='desktop' style={{ position: 'relative', zIndex: 10, paddingTop: '5rem', width: '100%', maxWidth: '600px'}}>
                <>
                  { children }
                </>
              </div>
           </div>
          </Page>
        )
      }
    </Media>
  )
}
