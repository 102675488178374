import { Modal } from 'react-bootstrap'
import styled from 'styled-components'

export const StyledModal = styled(Modal)`
  #vimeoContainer {
    // Vimeo default styling
    padding: 56.25% 0 0 0;
    position: relative;
    flex: 1;
  }

  .modal-header {
    .modal-title {
      color: white;
      font-size: 18px;
    }
    .close {
      color: white;
    }
  }

  .modal-content {
    background: rgba(0, 0, 0, 0.7);
  }

  .modal-body {
    padding: 0;
  }

  .modal-footer {
    padding: 0;
  }
`
