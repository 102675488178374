import { fixedAssets } from 'src/containers/PreCache'
import styled from 'styled-components'

interface OnDemandStyleProps {
  mobileHeight: number
}

export const OnDemandStyle = styled.div<OnDemandStyleProps>`
  background-image: url('${fixedAssets.boothBg}');
  background-size: cover;
  height: ${(props) => props.mobileHeight}px;
  overflow: hidden;

  .page-layout {
    backdrop-filter: blur(20px);
    background-color: rgba(255, 255, 255, 0.5);
  }

  .page-content {
    .grid-container {
      display: grid;
      grid-gap: 20px;
      grid-template-columns: repeat(auto-fit, 42%);
      grid-auto-rows: max-content;
      justify-content: center;
      text-align: center;
      position: relative;
      overflow-y: scroll;
      height: ${(props) => props.mobileHeight - 145}px;

      .card-thumbnail {
        display: block;
        cursor: pointer;
        position: relative;
        text-align: center;
        color: #525252;
        font-weight: 600;
        text-decoration: none;
        font-size: 15px;
        padding-bottom: 8px;
      }
    }
  }
  .content {
    width: 90%;
    height: 70%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    iframe {
      height: 100%;
      width: 100%;
    }
  }

`
