import React from 'react'
import { Route, Switch } from 'react-router-dom'
import routes from '../../routes'
import { CategorySelection } from './CategorySelection'

export default function OnDemandPage() {
  return (
    <>
      <Switch>
        <Route exact path={routes.onDemand} render={(props) => <CategorySelection {...props} />} />
        {/* <Route exact path={routes.onDemandBbs} render={(props) => <BestBasicScience {...props} />} />
        <Route exact path={routes.onDemandPoster} render={(props) => <AreaOfInterestSelection {...props} />} />
        <Route exact path={routes.onDemandOral} render={(props) => <AreaOfInterestSelection {...props} />} />
        <Route
          exact
          path="/on-demand/:category"
          render={(props) => <CategoryContent {...props} category={onDemandCategories.find((category) => category.destination === props.location.pathname)} />}
        />

        <Route exact path="/on-demand/e-poster-presentations/:areaOfInterest" render={(props) => <PosterAbstractList {...props} />} />
        <Route exact path="/on-demand/oral-presentations/:areaOfInterest" render={(props) => <OralAbstractList {...props} />} />

        <Route exact path="/on-demand/abstracts/:abstractId" render={(props) => <PresentationContainer {...props} />} /> */}
      </Switch>
    </>
  )
}
