import { isEmpty } from 'lodash'
import React, { FunctionComponent, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { OnDemandGallery } from '../../components/OnDemandGallery'
import { CurrentUserType } from '../../ducks/auth'
import Amplitude from '../../utils/amplitudeHelper'
import { useWindowSize } from '../../utils/useWindowSize'
import { OnDemandStyle } from './styled'
import MobileHeader from 'src/components/MobileHeader'

interface MobileOnDemandTypes {
  currentUser: CurrentUserType
}

const MobileOnDemand: FunctionComponent<MobileOnDemandTypes> = ({ currentUser }) => {
  const history = useHistory()
  const { pageTitle, previousPagePath } = useSelector((state) => state.onDemand)
  const { windowHeight } = useWindowSize()

  useEffect(() => {
    if (pageTitle.length > 0) Amplitude.visit(pageTitle)
  }, [currentUser.id, pageTitle])

  const goBack = () => {
    if (isEmpty(previousPagePath)) {
      history.goBack()
    } else {
      history.push(previousPagePath)
    }
  }

  return (
    <OnDemandStyle mobileHeight={windowHeight}>
      <div className="page-layout">
        <div className="page-content">
          <MobileHeader />
          {/* <button onClick={goBack} className="back-btn">
            <div className="back-icon">
              <svg width="17.301" height="26.839" viewBox="0 0 17.301 26.839">
                <g transform="translate(17.301 26.839) rotate(180)">
                  <path
                    d="M.459,14.462,13.147,26.407a1.637,1.637,0,0,0,2.216,0l1.48-1.393a1.415,1.415,0,0,0,0-2.084L6.79,13.419,16.845,3.909a1.415,1.415,0,0,0,0-2.084L15.363.432a1.637,1.637,0,0,0-2.216,0L.459,12.376A1.415,1.415,0,0,0,.459,14.462Z"
                    transform="translate(17.301 26.839) rotate(180)"
                    fill="#F8C605"
                  />
                </g>
              </svg>
              <small className="icon-label">Back</small>
            </div>
            <span>{pageTitle}</span>
          </button> */}
          <OnDemandGallery />
        </div>
      </div>
    </OnDemandStyle>
  )
}

export default MobileOnDemand
