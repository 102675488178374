import { createGlobalStyle } from 'styled-components'
import { COLORS, FONTS, SIZES } from './theme'

const GlobalStyle = createGlobalStyle`
  /* body > #zmmtg-root {
    display: none;
  } */

  html {
    color: ${COLORS.text};
    background: #FFF;
  }

  body {
    font-family: ${FONTS.primary};
  }

  #innerBody {
    background: linear-gradient(135deg, #ffffff, #8f9bab);
    background-size: 200% 200%;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: ${FONTS.secondary};
  }

  * {
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
  }

  *:focus {
    outline: none !important;
  }

  .text-red {
    color: ${COLORS.primary} !important;
  }

  .flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .spacer-100 {
    height: 100px;
  }

  .Toastify__progress-bar-theme--dark {
    background: #F4B31A;
  }
  .noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .with-shake {
    animation: wiggle 2s infinite;
  }
  @keyframes heartbeat {
    0%, 90%, 100% {
      transform: scale(1);
    }
    15% {
      transform: scale(0.8);
    }
    30% {
      transform: scale(1.08);
    }
    60% {
      transform: scale(0.93);
    }
  }
  @keyframes wiggle {
    0% {
      transform: rotate(0deg);
    }
    75% {
      transform: rotate(0deg);
    }
    80% {
      transform: rotate(-4deg);
    }
    85% {
      transform: rotate(9deg);
    }
    95% {
      transform: rotate(-9deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  @keyframes mousepoint {
    0% {
      left: 40%;
      bottom: 60%;
    }
    100% {
      left: 30%;
      bottom: 60%;
    }
  }
  @keyframes floating {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  @keyframes appear {
    to { opacity: 1; }
  }

  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-8%);
    }
    60% {
      transform: translateY(-4%);
    }
  }

  @keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }
    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  .register-btn {
    background: #EF8200;
    border-radius: 20px;
    border: none;
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;
    padding: 8px 2rem;
    transition: all 200ms ease;
    &:hover {
      background: linear-gradient(to right, #EF8200, #51ADB5);
    }
  }
  .animated-btn {
    color: #FFF;
    transition: all 0.5s;
    position: relative;
    line-height: 45px;
    height: 45px;
    text-align: center;
    width: 250px;
    cursor: pointer;
  }
  .animated-btn::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(255,255,255,0.1);
    transition: all 0.3s;
  }
  .animated-btn:hover::before {
    opacity: 0 ;
    transform: scale(0.5,0.5);
  }
  .animated-btn::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    transition: all 0.3s;
    border: 1px solid rgba(255,255,255,0.5);
    transform: scale(1.2,1.2);
  }
  .animated-btn:hover::after {
    opacity: 1;
    transform: scale(1,1);
  }
  .swiper-slide {
    overflow: hidden;
  }
  .swiper-pagination-bullet {
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    font-size: 12px;
    font-weight: 500;
    color: #000;
    opacity: 1;
    background: rgba(0, 0, 0, 0.2);
  }
  .swiper-pagination-bullet-active {
    color: #fff;
    background: #007aff;
    filter: brightness(1.7);
  }
  .swiper-button-next, .swiper-button-prev {
    height: 50px;
    filter: brightness(1.7);
  }
  .zoom-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 8px;
    width: 35px;
    height: 35px;
    cursor: pointer;
    background: #f8c605;
    color: #000;
    border-radius: 50%;
    padding: 8px;
    font-size: 18px;
    font-weight: 600;
    transition: all 300ms ease;
    &:hover {
      transform: scale(1.1);
      box-shadow: 0px 1px 10px 2px rgba(248,198,5,0.5);
      -webkit-box-shadow: 0px 1px 10px 2px rgba(248,198,5,0.5);
      -moz-box-shadow: 0px 1px 10px 2px rgba(248,198,5,0.5);
    }
  }
`

export default GlobalStyle
