import React, { FC, useEffect } from 'react'
import { CurrentUserType } from 'src/ducks/auth'
import { LobbyEntry } from '../../components/LobbyEntry'
import PageWithBg from '../../components/PageWithBg'
import config from '../../config'
import Amplitude from '../../utils/amplitudeHelper'
import { StyledLobby } from './styled'
import { fixedAssets } from 'src/containers/PreCache'

export const LobbyPage: FC<{ currentUser: CurrentUserType }> = ({ currentUser }) => {

  useEffect(() => {
    document.title = `${config.eventName} | Lobby`
  }, [])

  useEffect(() => {
    Amplitude.visit('Lobby')
  }, [currentUser.id])

  return (
    <>
      <StyledLobby>
        <PageWithBg bgImg={fixedAssets.lobby}>
          {config.lobbyEntries.map((entry) => {
            return <LobbyEntry key={entry.id} {...entry} currentUser={currentUser} />
          })}

          <div id="helpdesk">
            <img src={fixedAssets.helpdeskStand} width="100%" alt="Helpdesk Stand" />
          </div>
          <div id="profile">
            <img src={fixedAssets.speakersStand} width="100%" alt="Speakers Profile Stand" />
          </div>
        </PageWithBg>
      </StyledLobby>
    </>
  )
}