import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'
import { useListVals } from 'react-firebase-hooks/database'
import { NotificationDb } from '../../firebaseConfig'
import { useHistory } from 'react-router-dom'
import { COLORS, SIZES } from '../../styles/theme'
import TimeAgo from 'javascript-time-ago'
import ReactTimeAgo from 'react-time-ago'
import enMY from 'javascript-time-ago/locale/en-MY'
import { Loader } from '../shared/Loader'

TimeAgo.addDefaultLocale(enMY)

const StyledModal = styled(Modal)`
  text-align: left;

  .modal-content {
    background-color: rgba(255, 255, 255);
    border: none;
    border-radius: 0;
    
    margin-bottom: 24px;

    .modal-header {
      color: #08080A;
      border: none;
      .modal-title {
        font-size: 36px;
        font-weight: bold;
      }
      button {
        font-size: 36px;
        opacity: 1;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.9);
      }
    }
  }
  .divider {
    background: #F8C605;
    height: 3px;
  }
  .modal-body {
    padding-bottom: 36px;
    section {
      margin-bottom: 36px;
      h4 {
        font-style: italic;
        font-size: 23px;
        font-weight: bold;
      }
    }
  }
  #refresh-btn {
    border: solid 1px ${COLORS.primary};
    background: #FFAD2C;
    padding: 5px 10px;
    border-radius: 16px;
    margin: 36px auto;
    display: block;
  }
`
const MessageWrapper = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => (props.read ? 'transparent' : 'rgba(209, 219, 226, 0.95)')};
  border-bottom: 1px solid rgba(8, 8, 10, 0.5);
  
  div.text-content {
    width: 85%;
    color: #1E253A;
    p {
      font-size: 14px;
      margin: 0;
    }
    small.timestamp {
      font-size: 0.8em;
      color: rgba(0, 0, 0, 0.55);
      margin-bottom: 16px;
    }
  }
  div.btn-container {
    text-align: center;
    .animated-btn {
      font-size: 14px;
      color: #000;
      background: #F8C605;
      width: 100px;
    }
  }

  ${SIZES.desktop} {
    .modal-content {
      padding: 24px 72px;
    }

    div.btn-container {
      width: 15%;
      padding: 0px 24px;
    }
  }
`
export default function NotificationModal({ showModal, hideModal }) {
  const [values, loading, error] = useListVals(NotificationDb)
  const history = useHistory()
  const [notification, setNotification] = useState(null)

  const refreshPage = () => {
    window.location.reload()
  }
  const handleRedirect = (url) => {
    hideModal()
    history.push(url)
  }

  useEffect(() => {
    const newItems = values.length - localStorage.getItem('IA_notifications')
    let updated = values.sort((a,b)=> new Date(b.datetime) - new Date(a.datetime)).map((item,index) => {
      return {
        ...item,
        read: index < newItems ? false : true
      }
    })
    setNotification(updated)
    if (values.length > localStorage.getItem('IA_notifications')) {
      localStorage.setItem('IA_notifications', values.length)
    }
  }, [loading, values])

  return (
    <StyledModal
      show={showModal}
      onHide={hideModal}
      size="xl"
      aria-labelledby="notification-modal"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>NOTIFICATIONS</Modal.Title>
      </Modal.Header>
      <div className="divider"></div>
      <Modal.Body>
        <section>
          {loading ? (
              <Loader />
            ) : error ? (
              <button id="refresh-btn" onClick={refreshPage}>
                Connection failed. Click here to refresh.
              </button>
            ) : (
              notification.map((item, index)=>{
                return (
                  <MessageWrapper key={`notify-${index}`} read={item.read}>
                    <div className="text-content">
                      <p>{item.message}</p>
                      <small className="d-block timestamp">
                        {item.datetime && <ReactTimeAgo date={item.datetime} />}
                      </small>
                    </div>
                    <div className="btn-container">
                      {
                        item.url && (
                          <div onClick={()=>handleRedirect(item.url)} className="animated-btn">
                            <span>View</span>
                          </div>
                        )
                      }
                    </div>
                  </MessageWrapper>
                )
              })
            )
          }
        </section>
      </Modal.Body>
    </StyledModal>
  )
}
