import React from 'react'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'

const StyledModal = styled(Modal)`
  color: #505c7c;
  .modal-content {
    border: none;
    border-radius: 20px;
  }
  .modal-header {
    border: none;
    justify-content: flex-end;
  }
  .modal-body {
    padding: 16px 32px;
    h5 {
      font-size: 22px;
      font-weight: 600;
    }
    .content {
      width: 100%;
      overflow-x: hidden;
      padding-bottom: 16px;
    }
    .contact {
      width: 100%;
      margin: 16px 0;
      p > svg {
        margin-right: 8px;
      }
      a {
        color: #505c7c;
        text-decoration: none;
      }
    }
  }
`

export default function InfoModal({ booth, infoOpen, setInfoOpen }) {
  if (!infoOpen) return <></>

  return (
    <StyledModal
      show={infoOpen}
      onHide={()=>setInfoOpen(false)}
      size="lg"
      aria-labelledby="info-modal"
      centered
    > 
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <h5 className='text-center'>{booth.title}</h5>
        <div className="content" dangerouslySetInnerHTML={{ __html: booth.content }} />
      </Modal.Body>
    </StyledModal>
  )
}
