import styled from 'styled-components'

export const StyledLobby = styled.div`
  #current-user {
    pointer-events: none;
    position: absolute;
    right: 10px;
    bottom: 10px;
    font-size: 11px;
    font-style: italic;
    color: #fff;
  }
  #helpdesk, #profile {
    position: absolute;
    width: 10%;
    display: flex;
    justify-content: center;
    pointer-events: none;
  }

  #helpdesk {
    top: 70%;
    left: 77%;
    bottom: 2%;
    right: 20%;
  }

  #profile {
    top: 70%;
    left: 88%;
    bottom: 2%;
    right: 5%;
  }
  
`
