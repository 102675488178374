import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import routes from '../../routes'
import RegistrationTemplate from './RegistrationTemplate'
import { FONTS, SIZES } from 'src/styles/theme'
import { fixedAssets } from 'src/containers/PreCache'

const PostRegSection = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 2rem;
  

  .wrapper {
    background: linear-gradient(to bottom, #FCE7B6, #F4B64E);
    padding: 48px 24px; 
    border-radius: 1rem;
    min-width: 334px;
    text-align: center;
    width: 100%;
  }

  .info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #080F3A;
    font-weight: 700;
    font-size: 20px;
    line-height: normal
  }

  ${SIZES.desktop} {
    padding: 0;
    .wrapper {
      margin: 9rem 0 0 5rem;
      padding: 48px 116px;
      text-align: center;
    }
  }
`

export default function PostRegisterPage({ type }: { type: 'physical' | 'virtual' | 'insticlient' }) {
  const displayMessage = () => {
    switch (type) {
      case 'physical':
        return (
          <>
            <p className="subtitle">Thank you for your registration</p>
            <p className="subtitle2">You will receive a confirmation email soon</p>
          </>
        )
      case 'virtual':
        return (
          <>
            <p>Thank you for your registration<br />You will receive a confirmation email soon</p>
          </>
        )
      case 'insticlient':
        return (
          <>
            <p className="subtitle">Thank you for your registration</p>
            <p className="subtitle2">You will receive a confirmation email soon</p>
          </>
        )
    }
  }

  return (
    <RegistrationTemplate>
      <PostRegSection>
        <div className="wrapper">
          <img src={fixedAssets.checkIcon} className="d-block mx-auto mb-4" style={{ width: '130px', height: '130px' }} alt="success" />
          <div className="info">
            {displayMessage()}
          </div>
        </div>
      </PostRegSection>
    </RegistrationTemplate>
  )
}
