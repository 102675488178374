import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Redirect, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import config from '../../config'
import { loginUser } from '../../ducks/auth'
import routes from '../../routes'
import { ForgetPassword } from './ForgetPassword'
import RegistrationTemplate from '../registration/RegistrationTemplate'
import { Button, Form } from 'react-bootstrap'
import { fixedAssets } from 'src/containers/PreCache'
import { SIZES } from 'src/styles/theme'
import Media from 'react-media'

const Page = styled.div`
  min-height: 100vh;
  background-image: url(${fixedAssets.entrance});
  background-color: #212529;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100% 100%;

  ${SIZES.mobile} {
    background-image: url(${fixedAssets.entranceMobile});
    background-size: cover;
  }
`

const LoginBox = styled.div`
  border-radius: 8px;
  background: linear-gradient(180deg, #064267 0%, #0b1739 100%);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.3);
  padding: 1rem;
  min-width: 330px;

  p {
    color: #f4b64e;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
  }
`
const LoginWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: end;
  height: 100vh;
  padding: 2rem;
`

export const LoginPage = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const { isFetching, isAuthenticated } = useSelector((state) => state.auth)
  const history = useHistory()
  const dispatch = useDispatch()

  const handleLogin = (e) => {
    e.preventDefault()
    loginUser(dispatch, history, {
      email,
      password,
    })
  }
  const onPasswordInput = (e) => {
    setPassword(e.target.value)
  }

  const onEmailInput = (e) => {
    setEmail(e.target.value.toLowerCase())
  }

  useEffect(() => {
    document.title = `${config.eventName} | Login`
  }, [])

  const showHelpDesk = () => {
    const cpt = window.chaport
    cpt.on('ready', function () {
      cpt.open()
    })
    cpt.on('collapsed', function () {
      cpt.close()
    })
  }

  if (isAuthenticated) {
    return <Redirect to={routes.auditorium} />
  } else {
    return (
      <Media query={`(max-width: ${SIZES.mobileBreakpoint})`}>
        {(matches) =>
          matches ? (
            <Page>
              <LoginWrapper>
                <LoginBox>
                  <p>Login</p>
                  <Form onSubmit={handleLogin}>
                    <Form.Group>
                      <input className="form-control" type="email" placeholder="Email" value={email} onChange={onEmailInput} />
                    </Form.Group>
                    <Form.Group>
                      <input className="form-control" type="password" value={password} placeholder="Password" onChange={onPasswordInput} />
                    </Form.Group>

                    <ForgetPassword />

                    <div className="d-flex justify-content-end mt-4">
                      <Button
                        type="submit"
                        variant="default"
                        disabled={!(email.length && password.length) || isFetching}
                        onClick={handleLogin}
                      >
                        <svg width="76" height="76" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="38" cy="38" r="38" fill="url(#paint0_linear_222_283)" />
                          <path d="M28.1394 38.7926H49.1049" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                          <path
                            d="M38.6221 28.3099L49.1048 38.7926L38.6221 49.2754"
                            stroke="white"
                            stroke-width="4"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <defs>
                            <linearGradient id="paint0_linear_222_283" x1="38" y1="0" x2="38" y2="76" gradientUnits="userSpaceOnUse">
                              <stop stop-color="#F7DA74" />
                              <stop offset="1" stop-color="#C88131" />
                            </linearGradient>
                          </defs>
                        </svg>
                      </Button>
                    </div>
                  </Form>
                </LoginBox>
              </LoginWrapper>
            </Page>
          ) : (
            <Page>
              <LoginWrapper>
                <LoginBox>
                  <p>Login</p>
                  <Form onSubmit={handleLogin}>
                    <Form.Group>
                      <input className="form-control" type="email" placeholder="Email" value={email} onChange={onEmailInput} />
                    </Form.Group>
                    <Form.Group>
                      <input className="form-control" type="password" value={password} placeholder="Password" onChange={onPasswordInput} />
                    </Form.Group>

                    <ForgetPassword />

                    <div className="d-flex justify-content-end mt-4">
                      <Button
                        type="submit"
                        variant="default"
                        disabled={!(email.length && password.length) || isFetching}
                        onClick={handleLogin}
                      >
                        <svg width="76" height="76" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="38" cy="38" r="38" fill="url(#paint0_linear_222_283)" />
                          <path d="M28.1394 38.7926H49.1049" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                          <path
                            d="M38.6221 28.3099L49.1048 38.7926L38.6221 49.2754"
                            stroke="white"
                            stroke-width="4"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <defs>
                            <linearGradient id="paint0_linear_222_283" x1="38" y1="0" x2="38" y2="76" gradientUnits="userSpaceOnUse">
                              <stop stop-color="#F7DA74" />
                              <stop offset="1" stop-color="#C88131" />
                            </linearGradient>
                          </defs>
                        </svg>
                      </Button>
                    </div>
                  </Form>
                </LoginBox>
              </LoginWrapper>
            </Page>
          )
        }
      </Media>
    )
  }
}
