import React, { FC, useCallback, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { RoutesType } from 'src/routes'
import { StyledEntrySelectionModal } from './styled'

export interface MultiDestinationsType {
  name: string
  route: RoutesType
}

export interface EntrySelectionModalProps {
  destinations: Array<MultiDestinationsType>
  handleClose: () => void
  modalTitle: string
}

export const EntrySelectionModal: FC<EntrySelectionModalProps> = ({ destinations, handleClose, modalTitle }) => {
  const escFunction = useCallback(
    (event) => {
      if (event.keyCode === 27) {
        handleClose()
      }
    },
    [handleClose]
  )

  const clickOutsideToClose = (e) => {
    if ((e.target as any).id === 'ENTRY_SELECTION_MODAL') {
      handleClose()
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false)

    return () => {
      document.removeEventListener('keydown', escFunction, false)
    }
  }, [escFunction])

  return (
    <StyledEntrySelectionModal id={'ENTRY_SELECTION_MODAL'} onClick={clickOutsideToClose}>
      <div className="box-dialog shadow-lg">
        <p>{modalTitle}</p>
        {destinations.map((entry) => (
          <Link key={entry.name} to={entry.route}>
            <span>→</span>
            {entry.name}
          </Link>
        ))}
      </div>
    </StyledEntrySelectionModal>
  )
}
