import React, { useEffect } from 'react'
import { useWindowSize } from 'src/utils/useWindowSize'
import styled from 'styled-components'
import { Document, Page, pdfjs } from 'react-pdf'
import { MOBILE_THRESHOLD } from 'src/styles/theme'
import Amplitude from 'src/utils/amplitudeHelper'
import config from 'src/config'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const ProgrammeContainer = styled.div`
  background-color: #4b4b4b;
  min-height: 100vh;

  #pdfContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .pdfPageSelector {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 15px;
    background: #2d2c3c;

    button {
      color: white;
      background: transparent;
      font-size: 12px;
      border: solid 1px white;
      margin: 0 8px;
      border-radius: 8px;
      padding: 2px 8px;
      &:hover {
        background: white;
        color: #2d2c3c;
      }
    }
  }
  .appear {
    opacity: 0;
    animation: appear 500ms 500ms forwards;
  }
`

export default function PhysicalProgrammePage() {
  const { windowWidth } = useWindowSize()

  const pdfWidth = windowWidth <= MOBILE_THRESHOLD ? windowWidth : 744

  useEffect(() => {
    document.title = `${config.eventName} | Programme`
    Amplitude.visit('Physical Programme')
  }, [])

  return (
    <ProgrammeContainer>
      <div id="pdfContainer">
        <div className='appear'>
          <Document
            file="https://maybank-invest-asean-2023.s3.ap-southeast-1.amazonaws.com/_assets/IN-PERSON+-+Invest+ASEAN+2023+Programme.pdf"
            loading={<p className="text-center text-white mt-3">Loading...</p>}
          >
            <Page width={pdfWidth} pageNumber={1} />
          </Document>
        </div>
      </div>
    </ProgrammeContainer>
  )
}
