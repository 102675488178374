const indonesia = [
  { value: 'Avia Avian', label: 'Avia Avian' },
  { value: 'Bumi Resources Minerals', label: 'Bumi Resources Minerals' },
  { value: 'Bumi Serpong Damai', label: 'Bumi Serpong Damai' },
  { value: 'City Vision', label: 'City Vision' },
  { value: 'Japfa Comfeed Indonesia', label: 'Japfa Comfeed Indonesia' },
  { value: 'MNC Digital Entertainment', label: 'MNC Digital Entertainment' },
  { value: 'NFC Indonesia', label: 'NFC Indonesia' },
  { value: 'Wir Asia', label: 'Wir Asia' },
]

const vietname = [
  { value: 'Masan Group Corporation', label: 'Masan Group Corporation' },
  { value: 'Military Commercial JS Bank', label: 'Military Commercial JS Bank' },
  { value: 'Phu Nhuan Jewelry Joint Stock Company', label: 'Phu Nhuan Jewelry Joint Stock Company' },
  { value: 'Vietjet Aviation Joint Stock Company', label: 'Vietjet Aviation Joint Stock Company' },
  { value: 'Vietnam Technological and Commercial JS Bank (Techcombank)', label: 'Vietnam Technological and Commercial JS Bank (Techcombank)' },
  { value: 'VinGroup JSC / Vinhomes JSC / Vincom Retail JSC', label: 'VinGroup JSC / Vinhomes JSC / Vincom Retail JSC' },
  {
    value: 'Joint Stock Commercial Bank for Foreign Trade of Vietnam (Vietcombank)',
    label: 'Joint Stock Commercial Bank for Foreign Trade of Vietnam (Vietcombank)',
  },
]

const thailand = [
  { value: 'Bangchak Corp PCL', label: 'Bangchak Corp PCL' },
  { value: 'Sappe PCL', label: 'Sappe PCL' },
  { value: 'WHA Corp PCL', label: 'WHA Corp PCL' },
]

const philippines = [
  { value: 'ACEN Corp', label: 'ACEN Corp' },
  { value: 'Citicore Renewables', label: 'Citicore Renewables' },
  { value: 'Cosco Capital, Inc. / Keepers Holdings Inc', label: 'Cosco Capital, Inc. / Keepers Holdings Inc' },
]

const malaysia = [
  { value: 'Farm Fresh Bhd', label: 'Farm Fresh Bhd' },
  { value: 'QL Resources Bhd', label: 'QL Resources Bhd' },
  { value: 'Yinson Holdings Bhd', label: 'Yinson Holdings Bhd' },
]

const singapore = [
  { value: 'CapitaLand Integrated Commercial Trust', label: 'CapitaLand Integrated Commercial Trust' },
  { value: 'Dyna-Mac Holdings Ltd', label: 'Dyna-Mac Holdings Ltd' },
  { value: 'Propnex Limited', label: 'Propnex Limited' },
]

export const fullAttendeeTypes = [
  'Institutional Clients',
  'Retail Clients',
  'Private Wealth Clients',
  'Prime Brokerage Clients',
  'Corporate Clients',
  'Other Clients',
  'Staff of Maybank',
  'Media',
  'Others (please specify)',
]

export const corporateOptions = [
  {
    label: 'Indonesia',
    options: indonesia,
  },
  {
    label: 'Vietnam',
    options: vietname,
  },
  {
    label: 'Thailand',
    options: thailand,
  },
  {
    label: 'Philippines',
    options: philippines,
  },
  {
    label: 'Malaysia',
    options: malaysia,
  },
  {
    label: 'Singapore',
    options: singapore,
  },
]
