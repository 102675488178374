import React, { useEffect, useRef } from 'react'
import { fixedAssets } from 'src/containers/PreCache'
import Amplitude from 'src/utils/amplitudeHelper'
import styled from 'styled-components'
import config from '../config'
import { SIZES } from '../styles/theme'
import { useAutoMute } from '../utils/useAutoMute'
import PageWithBg from 'src/components/PageWithBg'
import VideoJS from 'src/components/VideoJS'
import videojs from 'video.js'
import { showGlobalModal } from 'src/ducks/layout'
import { GlobalModalTypes } from 'src/components/GlobalModals/GlobalModalContainer'
import { useDispatch } from 'react-redux'
import { post } from 'src/apis/apiService'
import { LIVE_SESSION_APIS } from 'src/apis/liveSessionApi'

const Page = styled.div`
  overflow: hidden;

  .content {
    ${config.layout.auditoriumVid}
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    // width: 649px;
    // height: 365px;
    iframe {
      height: 100%;
      width: 100%;
    }
  }

  .controls {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
  }

  .chat {
    max-width: 425px;
    position: fixed;
    padding-top: ${SIZES.navHeight};
    top: 0;
    left: 70%;
    bottom: 0;
    right: 5%;
    z-index: 30;
    margin: 0 auto;
    background: #242526;
  }
  #programme-btn {
    position: absolute;
    top: 10%;
    left: 0;
    margin-top: 30px;
    display: block;
    span {
      cursor: pointer;
      background: rgba(0, 0, 0, 1);
      opacity: 0.8;
      color: #f8c605;
      position: absolute;
      font-weight: 600;
      font-size: 18px;
      height: 60px;
      width: 180px;
      text-transform: uppercase;
      letter-spacing: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 28px 28px 0px 0px;
      transform: rotate(90deg) translateY(60px);
      transition: all 200ms ease-in;
      &:hover {
        color: #000;
        background: rgba(248, 198, 5, 1);
        border: 2px solid #000;
      }
    }
  }
`

const Btn = styled.div`
  border-radius: 4rem;
  color: #000;
  text-transform: capitalize;
  font-weight: bold;
  padding: 9px 43px;
  background: linear-gradient(90deg, #fce7b6 0%, #f4b64e 100%);
  cursor: pointer;
  width: fit-content;
  border: 0px;
  font-size: 1.2rem;

  &:hover {
    background: #f4b64e;
  }

  ${SIZES.mobile} {
    width: 100%;
    font-size: 8px 41px;
  }
`

export default function AuditoriumPage({ currentUser, streamUrl }) {
  useAutoMute()
  const dispatch = useDispatch()
  const playerRef = useRef(null)

  const videoJsOptions = {
    autoplay: true,
    controls: true,
    responsive: true,
    errorDisplay: false,
    fluid: true,
    poster: fixedAssets.auditoriumPoster,
    sources: [
      {
        src: streamUrl,
        type: 'application/x-mpegURL',
      },
    ],
  }

  const resetSession = () => {
    window.location.reload()
  }

  const checkHeartbeat = () => {
    setInterval(sendHeartbeat, 30000)
  }

  const sendHeartbeat = () => {
    post(
      LIVE_SESSION_APIS.HEARTBEAT,
      (_, status) => {
        status === 200 ? console.log('Heartbeat OK') : resetSession()
      },
      (error) => {
        console.error('Session validation error:', error)
        resetSession()
      }
    )
  }

  const trackDuration = (spentTime) => {
    const currentTime = new Date();
    const hours = currentTime.getHours();
    
    // Check if current time is past 9 AM (9)
    if (hours >= 9) {
      post(
        LIVE_SESSION_APIS.TRACK_USER,
        (_) => {},
        (error) => {
          console.error(error)
        },
        {
          spentTime,
        }
      )
    }
  }

  useEffect(() => {
    document.title = `${config.eventName} | Auditorium`
    Amplitude.visit('Auditorium')

    let startTime = Date.now()

    checkHeartbeat()

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        startTime = Date.now()
      }
      if (document.visibilityState === 'hidden') {
        const spentTime = Date.now() - startTime
        trackDuration(spentTime)
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  const handlePlayerReady = (player) => {
    playerRef.current = player

    // You can handle player events here, for example:
    player.on('waiting', () => {
      videojs.log('player is waiting')
    })

    player.on('dispose', () => {
      videojs.log('player will dispose')
    })
  }

  return (
    <Page>
      <PageWithBg bgImg={fixedAssets.auditorium}>
        <div className="mx-auto chat">
          <iframe
            src="https://app.sli.do/event/jic9GavpWJbCs3xSBUbJv7"
            height="100%"
            width="100%"
            frameBorder="0"
            style={{ minHeight: '600px' }}
            title="Slido"
          ></iframe>
        </div>
        <div className="content d-flex flex-column">
          {streamUrl.endsWith('.m3u8') ? (
            <>
              <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
            </>
          ) : (
            <iframe
              title="Vimeo"
              src="https://vimeo.com/event/4289759/embed/interaction"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
              // style="position:absolute;top:0;left:0;width:100%;height:100%;"
            ></iframe>

            // <iframe
            //   width="100%"
            //   height="500px"
            //   title="Live Video"
            //   src={`${streamUrl}?autoplay=1`}
            //   allow="autoplay; fullscreen; encrypted-media"
            //   allowFullScreen
            //   allowtransparency="true"
            //   frameBorder="0"
            // />
          )}
          <div className="controls">
            <Btn onClick={() => dispatch(showGlobalModal(GlobalModalTypes.Agenda))}>Agenda</Btn>
            <Btn onClick={() => dispatch(showGlobalModal(GlobalModalTypes.Speakers))}>Speakers</Btn>
          </div>
        </div>
        
      </PageWithBg>
    </Page>
  )
}
