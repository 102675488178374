import config from '../config'

export const LIVE_SESSION_APIS = {
  GET_SESSIONS: `${config.apiUrl}/api/live_sessions`,
  GET_SESSION: (sessionId) => `${config.apiUrl}/api/live_sessions/${sessionId}`,

  GET_IDS: `${config.apiUrl}/api/live_sessions_ids`,
  TRACK_USER: `${config.apiUrl}/api/track`,
  HEARTBEAT: `${config.apiUrl}/api/heartbeat`,
  JOIN_SESSION: (sessionId) => `${config.apiUrl}/api/live_sessions/${sessionId}/join`,
}