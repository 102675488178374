import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import Amplitude from '../../utils/amplitudeHelper'
import routes from '../../routes'
import config from '../../config'
import MobileNotice from './MobileNotice'
import meeting from '../../images/mobile/auditorium-icon.png'
import speakers from '../../images/mobile/speakers-icon.png'
import exhibition from '../../images/mobile/exhibition-icon.png'
import helpdesk from '../../images/mobile/helpdesk-icon.png'
import gallery from '../../images/mobile/gallery-icon.png'
import { showGlobalModal } from 'src/ducks/layout'
import { GlobalModalTypes } from 'src/components/GlobalModals/GlobalModalContainer'
import { fixedAssets } from 'src/containers/PreCache'

const MobileLobby = ({ currentUser }) => {
  const scrollRef = useRef(null)
  const dispatch = useDispatch()

  useEffect(() => {
    document.title = `${config.eventName} | Lobby`
    Amplitude.visit('Lobby')
  }, [currentUser.id])

  const showHelpDesk = () => {
    const cpt = window.chaport
    cpt.on('ready', function () {
      cpt.setVisitorData({
        name: currentUser.fullName,
        email: currentUser.email,
      })
      cpt.open()
    })
    cpt.on('collapsed', function () {
      cpt.close()
    })
    Amplitude.clickHelpDesk()
  }

  const handleScrollRight = () => {
    scrollRef.current.scrollBy({
      left: 400,
      behavior: 'smooth'
    });
  }
  const showVideo = () => {
    dispatch(showGlobalModal(GlobalModalTypes.Welcome))
  }
  const showExhibitionSelection = () => {
    dispatch(showGlobalModal(GlobalModalTypes.EntrySelection))
  }

  return (
    <Page>
      <div className='outer-wrapper'>
        <div className="lobby-image-wrapper"></div>

        <MobileNotice />

        <div className="menu">
          <p className="title">Virtual Halls</p>
          <div className="btn-container">
            <div id="moreBtn" onClick={handleScrollRight}>
              <em>More</em>
              <svg width="23.81" height="6.985" viewBox="0 0 23.81 6.985">
                <g transform="translate(0.75 1.06)">
                  <path d="M677.491,759h21.685" transform="translate(-677.491 -754)" fill="none" stroke="#272727" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                  <path d="M711.207,746.126l5.294,4.866" transform="translate(-694.501 -746.126)" fill="none" stroke="#272727" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                </g>
              </svg>
            </div>
          </div>

          <div className="list-wrapper" ref={scrollRef}>
            <div className="item-box">
              <div className="icon-wrapper">
                <img className="menu-icon" src={meeting} alt="icon" />
              </div>
              <p className="item-label">Auditorium</p>
              <Link to={routes.auditorium} className="item-button">
                Enter
              </Link>
            </div>

            <div className="item-box">
              <div className="icon-wrapper">
                <img className="menu-icon" src={gallery} alt="icon" />
              </div>
              <p className="item-label">Video On-Demand</p>
              <Link to={routes.onDemand} className="item-button">
                Enter
              </Link>
            </div>

            <div className="item-box">
              <div className="icon-wrapper">
                <img className="menu-icon" src={exhibition} alt="icon" />
              </div>
              <p className="item-label">Exhibition Hall</p>
              <div onClick={showExhibitionSelection} className="item-button">
                Enter
              </div>
            </div>

            <div className="item-box">
              <div className="icon-wrapper">
                <img className="menu-icon" src={helpdesk} alt="icon" />
              </div>
              <p className="item-label">Welcoming Video</p>
              <div onClick={showVideo} className="item-button">
                Enter
              </div>
            </div>

            <div className="item-box">
              <div className="icon-wrapper">
                <img className="menu-icon" src={helpdesk} alt="icon" />
              </div>
              <p className="item-label">Help Desk</p>
              <div onClick={showHelpDesk} className="item-button">
                Enter
              </div>
            </div>

            <div className="item-box">
              <div className="icon-wrapper">
                <img className="menu-icon" src={speakers} alt="icon" />
              </div>
              <p className="item-label">Speakers' Profile</p>
              <div onClick={() => dispatch(showGlobalModal(GlobalModalTypes.Speakers))} className="item-button">
                Enter
              </div>
            </div>
          </div>

        </div>
      </div>
    </Page>
  )
}

const Page = styled.div`
  background: #fff;
  background-repeat: no-repeat;
  background-position: top right;
  background-size: 100%;

  height: calc(100% - 50px);
  width: 100vw;
  overflow-y: scroll;
  position: absolute;

  .menu-icon {
    width: 100%;
  }
  .outer-wrapper {
    height: 100%;
    /* background-color: rgba(209, 219, 226, 1); */
  }
  .lobby-image-wrapper {
    background-image: url(${fixedAssets.lobby});
    background-size: cover;
    background-position: center;
    width: 100%;
    height: calc(100vh - 350px);
    min-height: 300px;
  }
  .menu {
    width: 100%;
    height: 240px;
    position: relative;
    background: #fff;
  }
  .title {
    font-size: 15px;
    font-weight: bold;
    font-style: italic;
    position: absolute;
    top: 10px;
    left: 15px;
  }
  .btn-container {
    display: flex;
    position: absolute;
    top: 12px;
    right: 20px;
  }
  #moreBtn {
    width: 80px;
    height: 22px;
    display: flex;
    align-items: center;
    color: #272727;
    justify-content: flex-end;
    em {
      font-size: 14px;
      margin-right: 8px;
    }
  }
  .list-wrapper {
    width: 100%;
    height: 160px;
    position: absolute;
    top: 45px;
    left: 0;
    overflow-x: scroll;
    display: flex;
    align-items: center;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  .item-box {
    width: 130px;
    height: 160px;
    margin-left: 20px;
    border: solid 3px #F8C605;
    border-radius: 10px;
    background: white;
    flex-shrink: 0;
    box-sizing: border-box;
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .icon-wrapper {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .item-label {
    font-size: 11px;
    font-weight: bold;
    text-align: center;
    color: #272727;
    margin: 0px;
  }
  .item-button {
    background-color: #F8C605;
    color: #272727;
    border: none;
    border-radius: 30px;
    font-size: 12px;
    font-weight: bold;
    width: 90px;
    height: 25px;
    margin: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media screen and (min-width: 540px) {
    .menu {
      height: 250px;
    }
    .list-wrapper {
      top: 65px;
    }
    .outer-wrapper {
      width: 540px;
      margin: auto;
    }
  }
`

export default MobileLobby
