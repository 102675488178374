import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { fixedAssets } from 'src/containers/PreCache'
import routes from 'src/routes'

const StyledHeader = styled.header`
  .backBtn {
    width: 90%;
    margin: 1rem auto;
    display: flex;
    align-items: center;
    small {
      font-size: 14px;
      margin-left: 8px;
    }
  }
`

export default function MobileHeader({ isLobby=false }) {
  const history = useHistory()

  const goBack = () => {
    history.push(routes.lobby)
  }
  
  return (
    <StyledHeader>
      <img src={fixedAssets.bannerM} width="100%" className="mx-auto d-block m-0" alt="event visual" />
      {!isLobby &&
        <div className='backBtn' onClick={goBack}>
          <svg width="23.81" height="6.985" viewBox="0 0 23.81 6.985">
            <g transform="translate(1.06 1.06)">
              <path d="M699.175,759H677.491" transform="translate(-677.175 -754)" fill="none" stroke="#2f2651" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
              <path d="M716.5,746.126l-5.294,4.866" transform="translate(-711.207 -746.126)" fill="none" stroke="#2f2651" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            </g>
          </svg>
          <small>Back to Lobby</small>
        </div>
      }
    </StyledHeader>
  )
}
