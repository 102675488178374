import React, { useEffect, useState } from 'react'
import { useListVals } from 'react-firebase-hooks/database'
import { useHistory } from 'react-router-dom'
import ReactTimeAgo from 'react-time-ago'
import styled from 'styled-components'
import { NotificationDb } from '../../firebaseConfig'
import { fixedAssets } from 'src/containers/PreCache'
import { Loader } from 'src/components/shared/Loader'
import MobileHeader from 'src/components/MobileHeader'

const MobileNotifications = () => {
  const [values, loading, error] = useListVals(NotificationDb)
  const history = useHistory()
  const [notification, setNotification] = useState(null)

  const refreshPage = () => {
    window.location.reload()
  }

  const handleRedirect = (url) => {
    history.push(url)
  }


  useEffect(() => {
    const newItems = values.length - localStorage.getItem('IA_notifications')
    let updated = values.sort((a,b)=> new Date(b.datetime) - new Date(a.datetime)).map((item,index) => {
      return {
        ...item,
        read: index < newItems ? false : true
      }
    })
    setNotification(updated)
    if (values.length > localStorage.getItem('IA_notifications')) {
      localStorage.setItem('IA_notifications', values.length)
    }
  }, [loading, values])

  return (
    <Page>
      <MobileHeader />
      <main className='shadow'>
        <h4 className='title'>Notifications</h4>
        {loading ? (
          <Loader />
          ) : error ? (
            <button onClick={refreshPage}>
              Connection failed. Click here to refresh.
            </button>
          ) : (
            notification.map((item, index)=>{
              return (
                <div className={`item-wrapper ${!item.read && 'unread'}`} key={index}>
                  <div className="item-content">
                    <p className='item-content-label'>{item.message}</p>
                    <p className='item-content-time'>{item.datetime && <ReactTimeAgo date={item.datetime} />}</p>
                  </div>
                  {
                    item.url &&
                    <button type="button" onClick={()=>handleRedirect(item.url)} className="item-button">
                      View
                    </button>
                  }
                </div>
              )
            })
          )
        }
      </main>
    </Page>
  )
}

const Page = styled.div`
  background: url(${fixedAssets.mobileBg});
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;

  height: calc(100% - 50px);
  width: 100vw;
  overflow-y: scroll;
  position: absolute;

  .title {
    font-size: 18px;
    font-weight: 800;
    padding-bottom: 8px;
    color: #08080A;
    border-bottom: 1px solid rgba(30, 37, 58, 0.2);
  }

  main {
    width: 98%;
    margin: auto;
    background: #fff;
    height: calc(100% - 60px);
    border-radius: 10px;
    padding: 1rem;
  }

  .time-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 5px;

    p {
      margin-bottom: 0px;
    }
  }
  .unread {
    background-color: rgba(209, 219, 226, 0.95);
  }

  .time-label {
    font-size: 13px;
    font-weight: bold;
    font-style: italic;
  }

  .time-clear {
    font-size: 11px;
  }

  .item-wrapper {
    width: 100%;
    padding: 15px 0px;
    padding-right: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 1px rgba(46, 75, 76, 0.5);
  }

  .item-content {
    width: 100%;
    margin-left: 5px;
    margin-bottom: 0;
  }

  .item-content-label {
    font-size: 12px;
    margin-bottom: 5px;
  }

  .item-content-time {
    font-size: 10px;
    margin-bottom: 0;
  }

  .item-button {
    margin-left: 10px;
    width: 70px;
    padding: 4px 0;
    background: #F8C605;
    color: #000;
    font-size: 11px;
    border: none;
    border-radius: 0px;
  }

  @media screen and (min-width: 540px) {
    .outer-wrapper {
      width: 540px;
      margin: auto;
    }
  }
`

export default MobileNotifications
