import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import routes from '../../routes'
import RegistrationTemplate from './RegistrationTemplate'
import { SIZES } from 'src/styles/theme'

const PreRegSection = styled.section`
  width: 100%;
  text-align: center;
  border-left: 2px solid rgba(0, 0, 0, 0.3);
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${SIZES.mobile} {
    border-left: none;
  }

  h3 {
    font-weight: 600;
  }
  .btn {
    border-radius: 10px;
    width: 100%;
    max-width: 400px;
    margin-top: 0.8em;
  }

  .disclaimer {
    max-width: 400px;
  }
`

export default function PreRegisterPage({ type }: { type: 'physical' | 'virtual' | 'insticlient' }) {
  const getTitle = () => {
    switch (type) {
      case 'physical':
        return 'Sign Up to Join In-Person'
      case 'virtual':
        return 'Sign Up to Join Virtually'
      case 'insticlient':
        return 'Sign Up for Corporate Access'
    }
  }

  const getLink = () => {
    switch (type) {
      case 'physical':
        return routes.physicalReg
      case 'virtual':
        return routes.virtualReg
      case 'insticlient':
        return routes.instiReg
    }
  }

  return (
    <RegistrationTemplate>
      <PreRegSection className="px-2">
        <h3>{getTitle()}</h3>
        <Link to={getLink()} className="btn btn-dark d-block py-2">
          GET STARTED
        </Link>
        {type === 'virtual' && (
          <p className="mt-4 disclaimer">
            If you have received an invitation for the In-Person or Corporate Access registration, please sign in using the link in your invitation.
          </p>
        )}
      </PreRegSection>
    </RegistrationTemplate>
  )
}
