import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'
import { useAutoMute } from 'src/utils/useAutoMute'
import { Loader } from '../shared/Loader'

const StyledModal = styled(Modal)`
  .modal-content {
    background: transparent;
    border: none;
  }
  .iframe-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      background: #000;
    }
  }
  .modal-header {
    padding: 8px;
    border: none;
    background: rgba(0, 0, 0, 0);
   
    button.close {
      opacity: 1;
      padding: 4px auto !important;
      z-index: 100;
      position: absolute;
      top: -3%;
      right: -3%;
      span {
        font-weight: 400;
        font-size: 48px;
        color: #f8c605;
        text-shadow: none;
      }
    }
  }
`

export default function IframeModal({ iframeOpen, setIframeOpen, iframe }) {
  useAutoMute()
  const [loading, setLoading] = useState(true)
  return (
    <StyledModal
      show={iframeOpen}
      onHide={()=>setIframeOpen(false)}
      size="xl"
      aria-labelledby="podcast-modal"
      centered
    > 
      <Modal.Header closeButton />

      <Modal.Body className="p-0">
        <div className="iframe-container" >
          {loading && <Loader fullPage={false} loading />}
          <iframe
            width="100%"
            height="100%"
            title={iframe.filename}
            src={`${iframe.url}?autoplay=1`}
            allow="autoplay; fullscreen; encrypted-media"
            allowFullScreen
            allowtransparency="true"
            frameBorder="0"
            onLoad={() => {
              setLoading(false)
            }}
          />
        </div>
      </Modal.Body>
    </StyledModal>
  )
}
