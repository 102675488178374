export const specialties = [
    "Cardiologist",
    "Cardio Fellow",
    "Internal Medicine",
    "General Practitioner",
    "Medical Officer",
    "Pharmacist",
    "Nurse",
    "Radiographer",
    "CVT",
    "Medical Assistant",
    "Industry",
    "Others",
  ]
  