import videoImage from '../../images/gallerium/onDemand/video-thumbnail.png'

import {
  onDemandVideosType,
} from './videos'

export interface onDemandCategoryType {
  id: number
  title: string
  categoryName?: string
  thumbnail: string
  profile?: string
  destination?: string
  embedUrl?: string
  videos?: Array<onDemandVideosType>
}

const onDemandCategories: Array<onDemandCategoryType> = [
  {
    id: 1,
    thumbnail: videoImage,
    title: "Opening Address by Dato Khairussaleh Ramli",
    embedUrl: "https://iframe.dacast.com/vod/1b1f6ca6-faef-317f-74bf-a4ce68f8e2d6/4426afae-1bb4-4e74-9972-a5a131e7b284",
  },
  {
    id: 2,
    thumbnail: videoImage,
    title: "Conference Context Setting",
    embedUrl: "https://iframe.dacast.com/vod/1b1f6ca6-faef-317f-74bf-a4ce68f8e2d6/39f21c0c-c71e-45af-b57a-5bd51034a55c",
  },
  {
    id: 3,
    thumbnail: videoImage,
    title: "ASEAN's Rising Star - The Next Wave",
    embedUrl: "https://iframe.dacast.com/vod/1b1f6ca6-faef-317f-74bf-a4ce68f8e2d6/1b80e99d-e29a-49f5-aac1-e90c8fe6b7e2",
  },
  {
    id: 4,
    thumbnail: videoImage,
    title: "Indonesia: In a Race to become the Next Economic Powerhouse",
    embedUrl: "https://iframe.dacast.com/vod/1b1f6ca6-faef-317f-74bf-a4ce68f8e2d6/4ed7c434-ad78-4e9b-8dae-2d601f093147",
  },
  {
    id: 5,
    thumbnail: videoImage,
    title: "President XI in his Third Term: Global Geopolitical Dynamics and Implications for ASEAN and Markets",
    embedUrl: "https://iframe.dacast.com/vod/1b1f6ca6-faef-317f-74bf-a4ce68f8e2d6/bfb1ca92-1ff0-4a41-93ef-5ceacd4e4caf",
  },
  {
    id: 6,
    thumbnail: videoImage,
    title: "Capital Allocations in the Post-Pandemic World",
    embedUrl: "https://iframe.dacast.com/vod/1b1f6ca6-faef-317f-74bf-a4ce68f8e2d6/79583b58-a6ce-4f57-a231-819d1eca8907",
  },
  {
    id: 7,
    thumbnail: videoImage,
    title: "ASEAN in Transition: Creating a Green Future",
    embedUrl: "https://iframe.dacast.com/vod/1b1f6ca6-faef-317f-74bf-a4ce68f8e2d6/f208c1ad-b18f-4221-90d3-d5acafad504b",
  },
  {
    id: 8,
    thumbnail: videoImage,
    title: "Investing through the Fog: Picking Winners and Managing Risk in Unprecendented Times",
    embedUrl: "https://iframe.dacast.com/vod/1b1f6ca6-faef-317f-74bf-a4ce68f8e2d6/93e6d3d4-03d9-4b5f-89dc-f5e4f92c571d",
  },
  {
    id: 9,
    thumbnail: videoImage,
    title: "Investment Reboot: Where are the Opportunities",
    embedUrl: "https://iframe.dacast.com/vod/1b1f6ca6-faef-317f-74bf-a4ce68f8e2d6/438698c6-5ea0-47dc-82ad-d2a7837fb989",
  },
  {
    id: 10,
    thumbnail: videoImage,
    title: "Press Conference - Part 1",
    embedUrl: "https://iframe.dacast.com/vod/1b1f6ca6-faef-317f-74bf-a4ce68f8e2d6/4ff29f67-0f75-491b-9db0-0aad309ee942",
  },
  {
    id: 11,
    thumbnail: videoImage,
    title: "Press Conference - Part 2",
    embedUrl: "https://iframe.dacast.com/vod/1b1f6ca6-faef-317f-74bf-a4ce68f8e2d6/3e4b2ad8-fd57-4a9f-9f35-bcae32b08117",
  },
  {
    id: 12,
    thumbnail: videoImage,
    title: "Is ESG Still Relevant For Global Investors? Who Will Win The Battle For Green Industrialisation?",
    embedUrl: "https://iframe.dacast.com/vod/1b1f6ca6-faef-317f-74bf-a4ce68f8e2d6/e0137063-5d4e-479d-b37a-46dd86eadd6f",
  },
  {
    id: 13,
    thumbnail: videoImage,
    title: "The Next Great Leap: Unearthing ASEAN Opportunities from AI & Digitalisation",
    embedUrl: "https://iframe.dacast.com/vod/1b1f6ca6-faef-317f-74bf-a4ce68f8e2d6/62bdd02d-ac7d-49ed-98d3-6cad30991991",
  },
  {
    id: 14,
    thumbnail: videoImage,
    title: "Wealth Management Trends: Evolving Demography and Islamic Wealth Management",
    embedUrl: "https://iframe.dacast.com/vod/1b1f6ca6-faef-317f-74bf-a4ce68f8e2d6/3273534d-7c6c-46a8-90fc-190b821f7539",
  },
  {
    id: 15,
    thumbnail: videoImage,
    title: "Winning With Alternatives: Insights From Top Managers",
    embedUrl: "https://iframe.dacast.com/vod/1b1f6ca6-faef-317f-74bf-a4ce68f8e2d6/f9c51fde-9300-41c8-ae98-45a0c56840ef",
  },
  {
    id: 16,
    thumbnail: videoImage,
    title: "Maybank + Economist: Global Market Outlook & Market Shaping Trends",
    embedUrl: "https://iframe.dacast.com/vod/1b1f6ca6-faef-317f-74bf-a4ce68f8e2d6/c50ac8e9-767b-4ee5-8a92-0aa2aa10759b",
  },
  {
    id: 17,
    thumbnail: videoImage,
    title: "Closing Remarks by Michael Oh-Lau",
    embedUrl: "https://iframe.dacast.com/vod/1b1f6ca6-faef-317f-74bf-a4ce68f8e2d6/cafa4748-88d8-44f8-8dca-235da8b95b81",
  }
]

export { onDemandCategories }
