import { Modal } from 'react-bootstrap'
import { COLORS } from 'src/styles/theme'
import styled from 'styled-components'

export const StyledWrapper = styled.div`
  #btn {
    text-align: center;
    background: none;
    margin: 0 auto;
    border: none;
    cursor: pointer;
    .action-text {
      font-size: 13px;
    }
  }
`
export const ForgetPasswordModal = styled(Modal)`
  .modal-content {
    color: #404040;
    border: none;
    padding: 16px;

    .modal-title {
      text-align: center;
    }
    .modal-body {
      padding-bottom: 0;
    }
    label {
      padding: 4px 0px;
    }
    input {
      border-radius: 4px;
      background: ${COLORS.lightGray};
      padding: 4px 12px;
      font-size: 13px;
      &::placeholder {
        font-size: 13px;
      }
    }
    button#verify-btn {
      display: block;
      margin: 0 auto;
      border: none;
      font-weight: 500;
      font-size: 14px;
      padding: 6px 18px;
    }
  }
  .modal-header {
    border: none;
    padding: 0 16px;
  }
`
