import React, { useEffect, useState } from 'react'
import { useWindowSize } from 'src/utils/useWindowSize'
import styled from 'styled-components'
import { Document, Page, pdfjs } from 'react-pdf'
import { MOBILE_THRESHOLD } from 'src/styles/theme'
import Amplitude from 'src/utils/amplitudeHelper'
import config from 'src/config'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const ProgrammeContainer = styled.div`
  background-color: #4b4b4b;
  min-height: 100vh;
  #pdfContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .pdfPageSelector {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 15px;
    background: #2d2c3c;

    button {
      color: white;
      background: transparent;
      font-size: 12px;
      border: solid 1px white;
      margin: 0 8px;
      border-radius: 8px;
      padding: 2px 8px;
      &:hover {
        background: white;
        color: #2d2c3c;
      }
    }
  }
  header {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 1rem;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background: #4b4b4b;
  }
  .session-tab {
    padding: 12px 8px;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    color: #fff;
    border-bottom: 2px solid white;
    transition: all 300ms ease-in;
  }
  .active {
    color: #f8c605;
    border-bottom: 2px solid #f8c605;
  }
  
  .appear {
    opacity: 0;
    animation: appear 500ms 500ms forwards;
  }
`

export default function SpeakerProfilePage() {
  const { windowWidth } = useWindowSize()
  const [selectedTab, setSelectedTab] = useState(1)
  const [numPages, setNumPages] = useState(null)
  
  const pdfWidth = windowWidth <= MOBILE_THRESHOLD ? windowWidth : 744

  useEffect(() => {
    document.title = `${config.eventName} | Speakers' Profile`
    Amplitude.visit("Speakers' Profile")
  }, [])

  return (
    <ProgrammeContainer>
      <header>
        <div className={`${selectedTab === 1 && 'active'} session-tab`} onClick={() => setSelectedTab(1)}>
          <span>Day 1 Speakers' Profile</span>
        </div>
        <div className={`${selectedTab === 2 && 'active'} session-tab`} onClick={() => setSelectedTab(2)}>
          <span>Day 2 Speakers' Profile</span>
        </div>
      </header>
      <div id="pdfContainer">
        {selectedTab === 1 &&
          <div className='appear'>
            <Document
              file="https://maybank-invest-asean-2023.s3.ap-southeast-1.amazonaws.com/_assets/Day+1+Speakers+Profiles.pdf"
              loading={<p className="text-center text-white mt-3">Loading...</p>}
              onLoadSuccess={({ numPages }) => {
                setNumPages(numPages)
              }}
            >
              {Array.apply(null, Array(numPages))
                .map((x, i)=>i+1)
                .map(page =>  <Page pageNumber={page} width={pdfWidth} />)
              }
            </Document>
          </div>
        }
        {selectedTab === 2 &&
          <div className='appear'>
            <Document
              file="https://maybank-invest-asean-2023.s3.ap-southeast-1.amazonaws.com/_assets/Day+2+Speakers+Profiles.pdf"
              loading={<p className="text-center text-white mt-3">Loading...</p>}
            >
              <Page width={pdfWidth} pageNumber={1} />
            </Document>
          </div>
        }
      </div>
    </ProgrammeContainer>
  )
}
