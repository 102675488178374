import React, { useEffect, useRef } from 'react'
import { fixedAssets } from 'src/containers/PreCache'
import VideoJS from 'src/components/VideoJS'
import videojs from 'video.js'

export default function StreamPlayer({ streamUrl }) {
  const playerRef = useRef(null)

  const videoJsOptions = {
    autoplay: true,
    controls: true,
    responsive: true,
    errorDisplay: false,
    fluid: true,
    poster: fixedAssets.auditoriumPoster,
    sources: [{
      src: streamUrl,
      type: 'application/x-mpegURL'
    }]
  }

  const handlePlayerReady = (player) => {
    playerRef.current = player

    // You can handle player events here, for example:
    player.on('waiting', () => {
      videojs.log('player is waiting')
    })

    player.on('dispose', () => {
      videojs.log('player will dispose')
    })
  }
  if (streamUrl && streamUrl.endsWith(".m3u8")) {
    return (
      <div>
        <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
      </div>
    )
  }
  else {
    return (
      <iframe
        width="100%"
        height="500px"
        title="Live Video"
        src={`${streamUrl}?autoplay=1`}
        allow="autoplay; fullscreen; encrypted-media"
        allowFullScreen
        allowtransparency="true"
        frameBorder="0"
      />
    )
  }
}
