import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getBooth, getBooths } from '../ducks/booth'
import Amplitude from '../utils/amplitudeHelper'
import { LazyLoadImage, LazyLoadComponent } from 'react-lazy-load-image-component'
import { fixedAssets } from 'src/containers/PreCache'
import PageWithBg from 'src/components/PageWithBg'

import IframeModal from 'src/components/GlobalModals/IframeModal'
import VideoModal from 'src/components/GlobalModals/VideoModal'
import PosterModal from 'src/components/GlobalModals/PosterModal'
import DownloadModal from 'src/components/GlobalModals/DownloadModal'
import { DocumentViewer } from 'src/components/GlobalModals/DocumentViewer'
import { Loader } from 'src/components/shared/Loader'
import InfoModal from 'src/components/GlobalModals/InfoModal'
import routes from 'src/routes'


const Page = styled.div`
  overflow: hidden;
  color: #505c7c;

  #button-container {
    position: absolute;
    padding-top: 30px;
    top: 2vh;
    left: 2%;
    .hot-buttons {
      width: 125px;
    }
    .back-hall {
      svg {
        height: 26px;
        width: 24px;
      }
    }
  }
  .hot-buttons,
  .hot-actions {
    background: linear-gradient(#FF9821, #F83636);
    border-radius: 40px;
    border: 2px solid #FF9821;
    padding: 8px 16px;
    color: #fff;
    margin: 0 8px;
    svg {
      width: 12px;
      height: 26px;
    }
    small {
      margin: 0;
      display: block;
      font-weight: 500;
      font-size: 70%;
    }
  }
  .booth-container {
    position: fixed;
    /* border: 1px solid red; */
    width: 70vw;
    max-width: 1240px;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -65%);
    opacity: 0;
    animation: appear 500ms 500ms forwards;
    
    .booth-img {
      object-fit: contain;
      height: 100%;
      width: 100%;
    }
    .hot-actions {
      background: #000;
      border-radius: 50%;
      border: none;
      padding: 0;
      box-shadow: 0px 5px 14px -4px #FFAD2C;
      transition: all 300ms ease;
      
      img,
      svg {
        transition: all 300ms ease;
        width: 28px;
        height: 28px;
      }
      small {
        transition: all 300ms ease;
        font-size: 1px;
        opacity: 0;
        color: #f8c605;
      }
      &:hover {
        small {
          font-size: 9px;
          opacity: 1;
        }
        img,
        svg {
          width: 23px;
          height: 23px;
        }
      }
    }
  }
`
const HotButton = styled.button`
  position: absolute;
  animation: heartbeat 7s infinite;
  width: 70px;
  height: 70px;
  ${(props) => props.css}
`

export default function BoothPage({ currentUser }) {
  const history = useHistory()
  const [showBtn, setShowBtn] = useState(false)
  const dispatch = useDispatch()
  const { boothId } = useParams()
  const { viewBooth, booths } = useSelector((state) => state.booth)

  const [iframeOpen, setIframeOpen] = useState(false)
  const [posterOpen, setPosterOpen] = useState(false)
  const [downloadOpen, setDownloadOpen] = useState(false)
  const [videoOpen, setVideoOpen] = useState(false)
  const [playVideo, setPlayVideo] = useState({})
  const [documentSelected, setDocumentSelected] = useState(null)
  const [infoOpen, setInfoOpen] = useState(false)

  useEffect(() => {
    dispatch(getBooth(boothId))
  }, [boothId, dispatch])

  useEffect(() => {
    if (viewBooth && viewBooth.id == boothId) {
      dispatch(getBooths(viewBooth.exhibition_hall.id))
      Amplitude.visitBooth(viewBooth.title)
    }
  }, [boothId, currentUser.id, dispatch, viewBooth])

  const handleModal = (video) => {
    Amplitude.watchBoothVideo(video.filename)
    setPlayVideo(video)
    setIframeOpen(true)
    // setVideoOpen(false)
  }

  const handleDownload = () => {
    Amplitude.clickHotButtons(viewBooth.title, 'pdf')
    setDownloadOpen(!downloadOpen)
  }
  const handleOpenDocument = (file) => {
    setDocumentSelected(file)
    // setDownloadOpen(!downloadOpen)
  }

  const handleVideoModal = () => {
    Amplitude.clickHotButtons(viewBooth.title, 'video')
    setVideoOpen(!videoOpen)
  }

  const handlePoster = () => {
    Amplitude.clickHotButtons(viewBooth.title, 'wallpaper')
    setPosterOpen(!posterOpen)
  }

  const handleInfo = () => {
    Amplitude.clickHotButtons(viewBooth.title, 'info')
    setInfoOpen(!infoOpen)
  }

  const navigatePrev = () => {
    setShowBtn(false)
    const navigateTo = booths.filter(booth=>booth.order===viewBooth.order-1)[0].id
    history.push(`/booths/${navigateTo}`)
  }
  const navigateNext = () => {
    setShowBtn(false)
    const navigateTo = booths.filter(booth=>booth.order===viewBooth.order+1)[0].id
    history.push(`/booths/${navigateTo}`)
  }
  return (
    <Page>
      <PageWithBg bgImg={fixedAssets.boothBg} style={{ opacity: showBtn ? 1 : 0}}>
        {!showBtn && <Loader fullPage loading />}
        {viewBooth && 
          <LazyLoadComponent>
            <div className="booth-container">
              <LazyLoadImage
                effect="black-and-white"
                afterLoad={() => setShowBtn(true)}
                className="booth-img"
                src={viewBooth.background_image.url}
                width="100%"
                alt="booth image"
                style={{ opacity: showBtn ? 1 : 0}}
              />
              {showBtn && (
                <>
                  {viewBooth.booth_videos.length > 0 && 
                    <HotButton css={viewBooth.button_position.tv} className="hot-actions" onClick={handleVideoModal} >
                      <svg width="26" height="26" viewBox="0 0 24 24" fill="none" stroke="#f8c605" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <circle cx="12" cy="12" r="10"></circle>
                        <polygon points="10 8 16 12 10 16 10 8"></polygon>
                      </svg>
                      <small>Video<br />Gallery</small>
                    </HotButton>
                  }
                  {viewBooth.content && 
                    <HotButton css={viewBooth.button_position.info} className="hot-actions" onClick={handleInfo}>
                      <svg width="37.098" height="37.098" viewBox="0 0 37.098 37.098">
                        <g transform="translate(-10.397 -16.423)">
                          <g transform="translate(10.397 16.423)" fill="none" stroke="#f8c605" strokeWidth="3">
                            <ellipse cx="18.549" cy="18.549" rx="18.549" ry="18.549" stroke="none"/>
                            <ellipse cx="18.549" cy="18.549" rx="17.049" ry="17.049" fill="none"/>
                          </g>
                          <text transform="translate(28.946 43.472)" fill="#f8c605" stroke="#f8c605" strokeWidth="0.5" fontSize="24" fontFamily="Montserrat-Regular, Montserrat"><tspan x="-3.228" y="0">i</tspan></text>
                        </g>
                      </svg>
                      <small>Booth<br />Info</small>
                    </HotButton>
                  }
                  {viewBooth.documents.length > 0 && 
                    <HotButton css={viewBooth.button_position.pdf} className="hot-actions" onClick={handleDownload}>
                      <svg width="35.678" height="35.678" viewBox="0 0 35.678 35.678">
                        <path d="M0,0H35.678V35.678H0Z" fill="none"/>
                        <path d="M28.758,2H10.919A2.982,2.982,0,0,0,7.946,4.973V22.812a2.982,2.982,0,0,0,2.973,2.973H28.758a2.982,2.982,0,0,0,2.973-2.973V4.973A2.982,2.982,0,0,0,28.758,2ZM16.123,13.149a2.227,2.227,0,0,1-2.23,2.23H12.406v1.858a1.115,1.115,0,1,1-2.23,0V10.92a1.491,1.491,0,0,1,1.487-1.487h2.23a2.227,2.227,0,0,1,2.23,2.23Zm7.433,2.973a2.227,2.227,0,0,1-2.23,2.23H18.352a.736.736,0,0,1-.743-.743V10.176a.736.736,0,0,1,.743-.743h2.973a2.227,2.227,0,0,1,2.23,2.23ZM29.5,10.548a1.123,1.123,0,0,1-1.115,1.115H27.272v1.487h1.115a1.115,1.115,0,0,1,0,2.23H27.272v1.858a1.115,1.115,0,1,1-2.23,0V10.92a1.491,1.491,0,0,1,1.487-1.487h1.858A1.123,1.123,0,0,1,29.5,10.548Zm-17.1,2.6h1.487V11.663H12.406Zm-8.919-5.2A1.491,1.491,0,0,0,2,9.433V28.759a2.982,2.982,0,0,0,2.973,2.973H24.3a1.487,1.487,0,0,0,0-2.973H6.46a1.491,1.491,0,0,1-1.487-1.487V9.433A1.491,1.491,0,0,0,3.487,7.946Zm16.352,8.176h1.487v-4.46H19.839Z" transform="translate(0.973 0.973)" fill="#f8c605"/>
                      </svg>
                      <small>PDF<br />Gallery</small>
                    </HotButton>
                  }
                  {viewBooth.wallpaper_gallery.length > 0 && 
                    <HotButton css={viewBooth.button_position.wp} className="hot-actions" onClick={handlePoster}>
                      <svg width="20" height="20" viewBox="0 0 45.448 45.448">
                        <path d="M0,0H45.448V45.448H0Z" fill="none"/>
                        <path d="M37.086,33.3V6.787A3.8,3.8,0,0,0,33.3,3H6.787A3.8,3.8,0,0,0,3,6.787V33.3a3.8,3.8,0,0,0,3.787,3.787H33.3A3.8,3.8,0,0,0,37.086,33.3ZM14.173,23.793l3.977,4.791,5.87-7.556a.951.951,0,0,1,1.515.019l6.647,8.862a.946.946,0,0,1-.757,1.515H8.719A.943.943,0,0,1,7.98,29.89L12.7,23.83A.934.934,0,0,1,14.173,23.793Z" transform="translate(2.681 2.681)" fill="#f8c605"/>
                      </svg>
                      <small>Image<br />Gallery</small>
                    </HotButton>
                  }
                </>
              )}
            </div>
            <div id='button-container'>
              <button className="hot-buttons back-hall" onClick={() => history.push(routes.lobby)}>
                <svg width="19" height="17.041" viewBox="0 0 19 17.041">
                  <path d="M14.25,17.042H4.75a2.378,2.378,0,0,1-2.375-2.375V6.578l-1.118.813a.786.786,0,0,1-.465.152A.792.792,0,0,1,.326,6.11L8.1.455a2.376,2.376,0,0,1,2.794,0L18.673,6.11a.791.791,0,0,1,.175,1.106.8.8,0,0,1-.641.326.783.783,0,0,1-.465-.152l-1.118-.813v8.088A2.378,2.378,0,0,1,14.25,17.042ZM8.708,8.334h1.583a2.377,2.377,0,0,1,2.375,2.374v4.75H14.25a.793.793,0,0,0,.792-.792V5.427L9.966,1.734a.793.793,0,0,0-.932,0L3.958,5.427v9.239a.793.793,0,0,0,.792.792H6.333v-4.75A2.377,2.377,0,0,1,8.708,8.334Zm0,1.583a.792.792,0,0,0-.792.791v4.75h3.167v-4.75a.792.792,0,0,0-.792-.791Z" transform="translate(0 0)" fill="#fff"/>
                </svg>
                <small className="extra-small">Back to Lobby</small>
              </button>
              {viewBooth.order !== 0 && (
                <button className="hot-buttons" onClick={navigatePrev}>
                  <svg width="17.301" height="26.839" viewBox="0 0 17.301 26.839">
                    <g transform="translate(17.301 26.839) rotate(180)">
                      <path d="M.459,14.462,13.147,26.407a1.637,1.637,0,0,0,2.216,0l1.48-1.393a1.415,1.415,0,0,0,0-2.084L6.79,13.419,16.845,3.909a1.415,1.415,0,0,0,0-2.084L15.363.432a1.637,1.637,0,0,0-2.216,0L.459,12.376A1.415,1.415,0,0,0,.459,14.462Z" transform="translate(17.301 26.839) rotate(180)" fill="#fff"/>
                    </g>
                  </svg>
                  <small>Previous Booth</small>
                </button>
              )}
              {viewBooth.order !== booths.length-1 && (
                <button className="hot-buttons" onClick={navigateNext}>
                  <svg width="17.301" height="26.839" viewBox="0 0 17.301 26.839">
                    <path d="M.459,12.376,13.147.432a1.637,1.637,0,0,1,2.216,0l1.48,1.393a1.415,1.415,0,0,1,0,2.084L6.79,13.419,16.845,22.93a1.415,1.415,0,0,1,0,2.084l-1.48,1.393a1.637,1.637,0,0,1-2.216,0L.459,14.462A1.415,1.415,0,0,1,.459,12.376Z" transform="translate(17.301 26.839) rotate(180)" fill="#fff"/>
                  </svg>
                  <small>Next Booth</small>
                </button>
              )}
            </div>

            <VideoModal videos={viewBooth.booth_videos} setVideoOpen={setVideoOpen} videoOpen={videoOpen} handleModal={handleModal} />
            {iframeOpen &&  <IframeModal iframeOpen={iframeOpen} setIframeOpen={setIframeOpen} iframe={playVideo} />}
            <DownloadModal setDocumentSelected={handleOpenDocument} files={viewBooth.documents} setDownloadOpen={setDownloadOpen} downloadOpen={downloadOpen} />
            <PosterModal images={viewBooth.wallpaper_gallery} posterOpen={posterOpen} setPosterOpen={setPosterOpen} />
            {documentSelected && <DocumentViewer file={documentSelected} setDocumentSelected={setDocumentSelected} />}
            <InfoModal booth={viewBooth} infoOpen={infoOpen} setInfoOpen={setInfoOpen} />
          </LazyLoadComponent>
        }
      </PageWithBg>
    </Page>
  )
}
