import React, { FC, useState } from 'react'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { onDemandCategories, onDemandCategoryType } from '../categories'
import { setPageTitle, setPreviousPage } from '../../../ducks/onDemand'
import routes from 'src/routes'
import { StyledModal } from '../CategoryContent/styled'
import { Modal } from 'react-bootstrap'
import Amplitude from 'src/utils/amplitudeHelper'
import { Loader } from 'src/components/shared/Loader'

export const CategorySelection: FC<RouteComponentProps> = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setPageTitle('Video On-Demand'))
    dispatch(setPreviousPage(routes.lobby))
  }, [dispatch])

  const [currentVideo, setCurrentVideo] = useState<null | onDemandCategoryType>(null)
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(true)

  const closeVimeoPlayer = () => {
    setShowModal(false)
  }
  
  const handlePlayback = (item) => {
    setShowModal(true)
    setLoading(true)
    Amplitude.clickGalleryItem(item)
    setCurrentVideo(item)
  }

  return (
    <>
      <div className="grid-container">
        {onDemandCategories.map((item) => (
          <div onClick={()=>handlePlayback(item)} className="card-thumbnail" key={item.title}>
            <img src={item.thumbnail} width="100%" alt="thumbnail" />
            <span>{item.title}</span>
          </div>
        ))}
      </div>
      <StyledModal show={showModal} onHide={closeVimeoPlayer} size="lg" aria-labelledby="vimeoPlayer" centered>
        <Modal.Header closeButton>
          <Modal.Title id="vimeoPlayer">
            <b>{currentVideo?.title}</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="vimeoContainer">
            {loading && <Loader loading={loading} />}
            <iframe
              src={`${currentVideo?.embedUrl}?autoplay=1`}
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
              title="Gallery Video"
              onLoad={() => setLoading(false)}
            ></iframe>
          </div>
        </Modal.Body>
      </StyledModal>
    </>
  )
}
